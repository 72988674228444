@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100vh;
  width: 100vw;
}

html, body, #root {
  margin: 0;
  padding: 0;
  display: flex;
  flex-grow: 1;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
