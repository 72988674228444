.yarl__thumbnails_container {
  background-color: #000000aa;
}

.yarl__container {
  background-color: #000000aa;
}

.yarl__thumbnails_bottom .yarl__thumbnails_container::before {
  background: none;
}

.yarl__thumbnails_bottom .yarl__thumbnails_container::after {
  background: none;
}